<template>
  <v-dialog
    v-model="showAdd"
    width="500"
    persistent
    @keydown.esc="onClose"
    @keydown.enter="save"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        @click="onNewClicked"
        color="bg-green-prosk"
        small
        class="white--text mr-2"
        v-on="on"
      >
        {{ item.isEdit ? $t("editAddress") : $t("createAddress") }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="text-h5 bg-prosk-secondary white--text">
        <span class="headline">
          {{ item.isEdit ? $t("editAddress") : $t("createAddress") }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-text-field
          :label="$t('domicile')"
          v-model="line_1"
          color="#59D79D"
          :hide-details="domicileErrors.length === 0"
          :error-count="domicileErrors.length"
          :error-messages="domicileErrors"
        ></v-text-field>
        <v-text-field
          :label="$t('postalCode')"
          v-model="postal_code"
          color="#59D79D"
          type="number"
          :hide-details="postalCodeErrors.length === 0"
          :error-count="postalCodeErrors.length"
          :error-messages="postalCodeErrors"
        ></v-text-field>
        <v-select
          :label="$t('city')"
          v-model="city"
          v-if="!$options.location.includes('br')"
          color="#59D79D"
          :items="cities"
          :hide-details="cityErrors.length === 0"
          :error-count="cityErrors.length"
          :error-messages="cityErrors"
        ></v-select>
        <v-text-field
          v-if="$options.location.includes('br')"
          :label="$t('city')"
          v-model="city"
          color="#59D79D"
          :items="cities"
          :hide-details="cityErrors.length === 0"
          :error-count="cityErrors.length"
          :error-messages="cityErrors"
        ></v-text-field>
        <v-text-field
          :label="$t('state')"
          v-model="state"
          color="#59D79D"
          :hide-details="stateErrors.length === 0"
          :error-count="stateErrors.length"
          :error-messages="stateErrors"
        ></v-text-field>
        <!--<v-text-field
            :label="$t('country')"
            v-model="country"
            color="teal"
            :hide-details="countryErrors.length === 0"
            :error-count="countryErrors.length"
            :error-messages="countryErrors"
        ></v-text-field>-->
        <v-checkbox
          v-model="is_default"
          :hide-details="checkedError.length === 0"
          :error-count="checkedError.length"
          :error-messages="checkedError"
          :label="$t('isDefault')"
          color="#59D79D"
        >
        </v-checkbox>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <cancel-button :cancel="onClose" :message="$t('cancel')"/>
        <save-button :save="save" :loading="loading" :message="$t('save')"/>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import errorDialog from '@/components/socialvue/mixins/errorDialog';
import SaveButton from '@/components/socialvue/buttons/SaveButton.vue';
import CancelButton from '@/components/socialvue/buttons/CancelButton.vue';

export default {
  name: 'UserAddressesDialog',
  created () {
      this.setImportedConstants();
  },
  components: { CancelButton, SaveButton },
  props: ['showAdd', 'onClose', 'item', 'newItem'],
  mixins: [errorDialog],
  data () {
    return {
      postalCodeErrors: [],
      cityErrors: [],
      stateErrors: [],
      checkedError: [],
      domicileErrors: [],
      countryErrors: [],
      postal_code: null,
      state: null,
      line_1: null,
      city: null,
      country: null,
      is_default: false,
      loading: false,
      cities: [
        'Artigas',
        'Canelones',
        'Cerro Largo',
        'Colonia',
        'Durazno',
        'Flores',
        'Florida',
        'Lavalleja',
        'Maldonado',
        'Montevideo',
        'Paysandú',
        'Río Negro',
        'Rivera',
        'Rocha',
        'Salto',
        'San José',
        'Soriano',
        'Tacuarembó',
        'Treinta y Tres'
      ]
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser'
    })
  },
  watch: {
    showAdd: function (value) {
      if (value && this.item && this.item.isEdit) {
        this.postal_code = this.item.postal_code;
        this.city = this.item.city;
        this.country = this.item.country;
        this.state = this.item.state;
        this.line_1 = this.item.line_1;
        this.is_default = this.item.is_default;
      }
    }
  },
  methods: {
    setImportedConstants () {
        this.$options.location = process.env.VUE_APP_LOCATION;
    },
    onNewClicked () {
      this.resetErrors();
      this.reset();
      this.newItem();
    },
    resetErrors () {
      this.postalCodeErrors = [];
      this.cityErrors = [];
      this.stateErrors = [];
      this.checkedError = [];
      this.countryErrors = [];
      this.domicileErrors = [];
    },
    reset () {
      this.postal_code = null;
      this.loading = false;
      this.city = null;
      this.state = null;
      this.country = null;
      this.line_1 = null;
      this.is_default = false;
    },
    ...mapActions({
      updateUser: 'auth/updateUser',
      updatePercentage: 'auth/updatePercentage'
    }),
    save () {
      this.resetErrors();
      if (!this.line_1) {
        this.domicileErrors.push(this.$t('fieldDomicileRequired'));
      }
      if (!this.state) {
        this.stateErrors.push(this.$t('fieldStateRequired'));
      }
      if (!this.postal_code) {
        this.postalCodeErrors.push(this.$t('fieldPostalCodeRequired'));
      }
      if (!this.city) {
        this.cityErrors.push(this.$t('fieldCityRequired'));
      }
      if (this.city && this.postal_code && this.state && this.line_1) {
        this.loading = true;
        const data = {
          user_id: this.currentUser.id,
          postal_code: this.postal_code,
          state: this.state,
          line_1: this.line_1,
          city: this.city,
          country: this.country,
          is_default: this.is_default
        };
        if (!this.item.isEdit) {
          const url = `${process.env.VUE_APP_BASE_URL}/api/addresses`;
          axios
            .post(url, data)
            .then(() => {
              this.loading = false;
              this.onClose();
              this.updateUser(this.currentUser.id);
              this.updatePercentage(this.currentUser.id);
            })
            .catch((error) => {
              this.loading = false;
              this.showError(error);
            });
        } else {
          const urlEdit = `${process.env.VUE_APP_BASE_URL}/api/addresses/`;
          axios
            .put(urlEdit + this.item.id, data)
            .then(() => {
              this.updateUser(this.currentUser.id);
              this.updatePercentage(this.currentUser.id);
              this.loading = false;
              this.onClose();
            })
            .catch((error) => {
              this.loading = false;
              this.showError(error);
            });
        }
      }
    }
  }
};
</script>
